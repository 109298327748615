import { HttpClient, HttpParams } from "@angular/common/http";
import type { OnDestroy, Signal, WritableSignal } from "@angular/core";
import { Injectable, inject, signal } from "@angular/core";
import * as LaunchDarklyClient from "launchdarkly-js-client-sdk";
import { firstValueFrom } from "rxjs";
import type { LaunchFlagSettings } from "src/app/shared/services/launch-flags/launch-flags.models";
import { environment } from "src/environments/environment";

type RequiredLaunchFlagSettings = LaunchFlagSettings & {
   clientSideID: string;
};

@Injectable({ providedIn: "root" })
export class LaunchFlagsService implements OnDestroy {
   private readonly http = inject(HttpClient);
   private readonly contextEndpoint = `${environment.flannelUrl}/launchFlags/context`;
   private client: LaunchDarklyClient.LDClient | undefined;
   private readonly flags: Map<string, WritableSignal<any>> = new Map();

   public ngOnDestroy(): void {
      /**
       * Close the client when the service is destroyed so that we don't leak resources and so it will report analytics
       * events to launchdarkly.
       */
      this.client?.close();
   }

   /**
    * @param name The name of the flag to get.
    * @param defaultValue The default value of the flag if it has not been set. This value is required as not all env support launch darkly.
    * In 21CFR flags are not allowed, so whatever default value is passed in will be used in those environments.  Your code should assume the
    * default value is the "off" state so that flagged code does not run in these environments.
    */
   public getFlag<T>(name: string, defaultValue: T): Signal<T> {
      let flag = this.flags.get(name);

      if (!flag) {
         const value = this.client?.variation(name, defaultValue);
         flag = signal(value ?? defaultValue);
         this.flags.set(name, flag);
      }

      return flag.asReadonly();
   }

   /**
    * Background:
    *    Launch flags need to be setup as early in the application lifecycle as possible.  This helps
    *    mitigate potential issues with UI flicker caused by a launch flags default state being false leading to a
    *    feature being off until the launch flags service is initialized at which point the page rerenders.  This
    *    rerender can cause the UI to "flicker".
    *
    *    We pay for launch darkly based on usage. Because of this we don't want to connect to launch darkly on
    *    public facing pages or it could cause our costs to skyrocket.
    *
    * Why:
    *    Angular lazy loads its services. Without using this initialize function at the app root level the service would not
    *    get initialized until the first component that uses it is created. This effectively makes the initialization random
    *    as the first component to use it will change as flags are added and removed. Exposing an initialize method allows
    *    us to control when the service connects to launch darkly.
    *
    * WARNING:
    *    Launch flags should never be used on public facing pages. We are currently on a usage-based plan
    *    and a public facing page could cause our costs to skyrocket.
    *
    * @returns A promise that resolves when the LD client has been initialized.
    */
   public async initialize(): Promise<void> {
      // Don't initialize the LD client if one already exists or we're on a public route.
      if (this.client || this.isOnPublicRoute()) {
         return;
      }

      try {
         const settings = await this.getSettings();
         const options = this.getLaunchDarklyOptions(settings);

         this.client = LaunchDarklyClient.initialize(
            settings.clientSideID,
            settings.context,
            options,
         );

         this.client.on("change", (flagChanges: LaunchDarklyClient.LDFlagChangeset) => {
            for (const name of Object.keys(flagChanges)) {
               this.flags.get(name)?.set(flagChanges[name].current);
            }
         });

         const timeoutSeconds = 5;
         await this.client.waitForInitialization(timeoutSeconds);
      } catch (error) {
         console.error(
            `LaunchDarkly client initialization failed. Default flag values will be used.`,
            error,
         );
      }
   }

   private getLaunchDarklyOptions(
      settings: RequiredLaunchFlagSettings,
   ): LaunchDarklyClient.LDOptions {
      const options: LaunchDarklyClient.LDOptions = {
         streaming: true,
      };
      if (environment.useLaunchDarklySecureMode && settings.secureHash) {
         options.hash = settings.secureHash;
      }

      return options;
   }

   private async getSettings(): Promise<RequiredLaunchFlagSettings> {
      try {
         const includes = ["clientSideID"];
         if (environment.useLaunchDarklySecureMode) {
            includes.push("secureHash");
         }

         const settings = await firstValueFrom(
            this.http.get<LaunchFlagSettings>(this.contextEndpoint, {
               params: new HttpParams().set("include", includes.join(",")),
            }),
         );

         if (!settings.clientSideID) {
            throw new Error(
               "Could not initialize launch flags service. Missing Client Side ID for Launch Darkly. Default flag values will be used.",
            );
         }

         /**
          * Fighting with typescript. TS for some reason cant tell that clientSideID will not be undefined on the settings object.
          * Destructuring the object and explicitly setting the clientSideID property on the return object fixes this.
          */
         return {
            ...settings,
            clientSideID: settings.clientSideID,
         };
      } catch (error) {
         throw new Error(
            "Failed to retrieve Launch Darkly context and settings. Default flag values will be used.",
         );
      }
   }

   /**
    * The list used here was determined by looking for routes with {requireLogin: false} in the routing setup.
    *
    * We use the window object to check the current URL because the Angular router is not yet initialized
    * when this code runs.
    *
    * We are currently on a usage-based plan with launch darkly and a public facing page could cause our costs to skyrocket.
    *
    * @returns Whether or not the current page is a public route.
    */
   private isOnPublicRoute() {
      const publicURLs = [
         "/loc-problem",
         "/mobile-loc-problem",
         "/global-problem",
         "/mobile-global-problem",
         "/demoSwitchBoard",
         "/thanks",
         "/viewExternalTask",
         "/check-work-requests",
         "/wr",
         "/loggedInAsExternalUser",
      ];

      for (const url of publicURLs) {
         if (window.location.href.includes(url)) {
            return true;
         }
      }

      return false;
   }
}
